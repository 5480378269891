
import * as wjCore from "@grapecity/wijmo";
import { hasClass, isNumber, CollectionView, isDate, addClass, DataType } from "@grapecity/wijmo";
import { CellRange, CellEditEndingEventArgs } from "@grapecity/wijmo.grid";
import { Selector, BooleanChecker } from "@grapecity/wijmo.grid.selector"; // 체크 박스
import DOMPurify from "dompurify";
import cookie from "react-cookies";

import _, { isNaN, isNull } from "lodash";
import moment from "moment/moment";

import { ApiManager } from "common/lib/ApiUtil";

import { GridEdited } from "./GridEdited";
import { GridEditedByColumnId } from "./GridEditedByColumnId";
import { GridContextMenu } from "./GridContextMenu"; // 컨텍스트 메뉴

import GridUserMerge from "./GridUserMerge";
import GridUserMergeByKey from "./GridUserMergeByKey";
import GridUserMergeSelector from "./GridUserMergeSelector";

export const ComUtils = () => { };

ComUtils._altTitleInfo = "Info";
ComUtils._altTitleError = "Error";
ComUtils._altTitleWarn = "Warn";
ComUtils._altTitleConfirm = "Confirm";

Selector.prototype._click = function (e) {
  let _CLS_CB_ITEM = "wj-column-selector";

  if (!e.defaultPrevented && e.target instanceof HTMLElement) {
    let t = this._grid,
      o = this._col,
      i = e.target,
      n = t.hitTest(i);
    if (o && n && n.getColumn() === o) {
      if (i instanceof HTMLInputElement && hasClass(i, _CLS_CB_ITEM)) {
        let s = void 0,
          l = n.panel.rows,
          r = l[n.range.topRow];

        let headerClick = false;
        if (l === t.columnHeaders.rows) {
          s = new CellRange(0, 0, t.rows.length - 1, 0);
          if (this._isBound) {
            let c = t.selection;
            c.col = c.col2 = o.index;
            t.select(c);
          }

          headerClick = true;
        } else s = this._isGroupRow(r) ? r.getCellRange() : n.range;
        if (s.isValid) {
          this._setRangeChecked(i.checked, s, headerClick);
          this.onItemChecked();
        }
        t.invalidate();
        e.preventDefault();
        return;
      }
      if (
        hasClass(i, "wj-cell") &&
        t.bigCheckboxes &&
        this._isBound &&
        (this._isFixedCol || this._isGroupRow(n.getRow()))
      ) {
        var a = i.querySelector("input." + _CLS_CB_ITEM);
        if (a instanceof HTMLInputElement) {
          a.click();
          e.preventDefault();
        }
      }
    }
  }
};
Selector.prototype._setRangeChecked = function (e, t, allChange) {
  var o = this,
    i = this._grid,
    n = i.rows,
    s = this._col,
    l = s ? s._binding : null,
    r = i.selection,
    c = this._isBound ? i.editableCollectionView : null;
  if (!this._isBound || (!i.isReadOnly && c && l)) {
    var a = !1;
    if (c) {
      var h = !0;
      c instanceof CollectionView && !c.refreshOnEdit && (h = !1);
      if (h) {
        a = !0;
        c.beginUpdate();
      }
    }
    i.deferUpdate(function () {
      let hasChange = false;
      for (var r = t.bottomRow; r >= t.topRow; r--) {
        var a = n[r],
          h = a.dataItem;
        if (
          i.hasOwnProperty("customCheckBoxChecked") &&
          i.hasOwnProperty("hasDisableSelector")
        ) {
          /*
                     if (h.uiFlag ==="I")
                         if (o._isGroupRow(a)) i.childItemsPath && !o._isBound && (a.isSelected = e);
                         else if (o._isBound) {
                         if (h[s.binding] != e) {
                             c && c.editItem(h);
                             var d = new CellRange(r, s.index),
                                 u = new CellEditEndingEventArgs(i.cells, d, h[s.binding]);
                             if (i.onCellEditEnding(u)) {
                                 l.setValue(h, e);
                                 i.onCellEditEnded(u)
                             }
                         }
                     } else {
                         */
          if (h.uiFlag === "I") {
            if (allChange) {
              a.isSelected = !i.customCheckBoxChecked;
              hasChange = true;
            } else {
              a.isSelected = e;
            }
          }
          //}
        } else {
          if (h)
            if (o._isGroupRow(a))
              i.childItemsPath && !o._isBound && (a.isSelected = e);
            else if (o._isBound) {
              if (h[s.binding] !== e) {
                c && c.editItem(h);
                var d = new CellRange(r, s.index),
                  u = new CellEditEndingEventArgs(i.cells, d, h[s.binding]);
                if (i.onCellEditEnding(u)) {
                  l.setValue(h, e);
                  i.onCellEditEnded(u);
                }
              }
            } else {
              a.isSelected = e;
            }
        }
      }

      if (
        i.hasOwnProperty("customCheckBoxChecked") &&
        i.hasOwnProperty("hasDisableSelector") &&
        allChange &&
        hasChange
      ) {
        i.customCheckBoxChecked = !i.customCheckBoxChecked;
      }
    });
    if (c) {
      c.commitEdit();
      a && c.endUpdate();
      i.selection = r;
    }
  }
};

ComUtils.gridInit = (gridObject, gridInitInfo) => {
  if (gridInitInfo === undefined || gridInitInfo === null) {
    gridInitInfo = {};
  }

  if (gridInitInfo) {
    // headerHeight     : 45
    // selectionMode    : 3
    // hasCheckBox      : true // isChecked 컬럼 사용
    // hasRowHeaderCheckBox : false //rowHeader 체크 박스 (넘버링 X)
    // boolleanCheckBox : "" /booleanChecker 사용 (속도.. 데이타 많으면 별로...)
    // showCheckAll     : false
    // hasDisableCheck  : true      // uiFlag 값이 I가 아니경우 Disabled
    // useFilter        : true
    // canEdit          : true      // 수정 표시
    // canAreaInfo      : true      // 선택영역 정보 표시
    // allowPinning     : false     // 컬럼 틀고정
    // frozenColumns    :           // 기본틀고정 인덱스
    // useMerge         : false     // 셀 병합 사용             컬럼 설정 에서 allowMerging:true 속성이 필요함
    // useContextMenu   : false     // 그리드 ContextMenu
    // useDefaultEditedEvent : true // editeded event 기본사용
    // isReadOnly       : false     // 읽기 전용
    // hasDisableCheck 가 true 일 경우 customCheckBoxChecked 속성이 그리드에 추가 기본값은 false;
    // hasShowNumbering : false (넘버링 사용 여부)
    // 예제 ) ComUtils.gridInit(sender, {headerHeight :45, selectionMode: 6, hasCheckBox:true , showCheckAll: false, hasDisableCheck:true, hasFilter:true,canEdit:true, canAreaInfo:true })

    /// isPopup 원인 확인이 필요함 팝업에서 gridObject.stickyHeaders = true; 이 설정 되면 그리드가 보이지 않는 현상
    // if (gridInitInfo.headerHeight === undefined     || gridInitInfo.headerHeight === null)      {gridInitInfo.headerHeight = 45;}
    /*
        if (gridInitInfo.useFilter === undefined        || gridInitInfo.useFilter === null)         {gridInitInfo.useFilter = true;}
        if (gridInitInfo.selectionMode === undefined    || gridInitInfo.selectionMode === null)     {gridInitInfo.selectionMode = 6;}
        if (gridInitInfo.hasCheckBox === undefined      || gridInitInfo.hasCheckBox === null)       {gridInitInfo.hasCheckBox = true;}
        if (gridInitInfo.showCheckAll === undefined     || gridInitInfo.showCheckAll === null)      {gridInitInfo.showCheckAll = false;}
        if (gridInitInfo.hasDisableCheck === undefined  || gridInitInfo.hasDisableCheck === null)   {gridInitInfo.hasDisableCheck = true;}
        if (gridInitInfo.canEdit === undefined          || gridInitInfo.canEdit === null)           {gridInitInfo.canEdit = true;}
        if (gridInitInfo.canAreaInfo === undefined      || gridInitInfo.canAreaInfo === null)       {gridInitInfo.canAreaInfo = true;}
        if (gridInitInfo.allowPinning === undefined     || gridInitInfo.allowPinning === null)      {gridInitInfo.allowPinning = false;}
        if (gridInitInfo.useMerge === undefined         || gridInitInfo.useMerge === null)          {gridInitInfo.useMerge = false;}
        if (gridInitInfo.useContextMenu === undefined   || gridInitInfo.useContextMenu === null)    {gridInitInfo.useContextMenu = false;}
        if (gridInitInfo.useDefaultEditedEvent === undefined   || gridInitInfo.useDefaultEditedEvent === null)    {gridInitInfo.useDefaultEditedEvent = true;}
        if (gridInitInfo.isReadOnly === undefined   || gridInitInfo.isReadOnly === null)    {gridInitInfo.isReadOnly = false;}
        if (gridInitInfo.isPopup === undefined   || gridInitInfo.isPopup === null)    {gridInitInfo.isPopup = false;}

        if (gridInitInfo.isPopup === undefined   || gridInitInfo.isPopup === null)    {gridInitInfo.isPopup = false;}
        */

    gridInitInfo.useFilter = gridInitInfo?.useFilter ?? true;
    gridInitInfo.selectionMode = gridInitInfo?.selectionMode ?? 6;
    gridInitInfo.hasCheckBox = gridInitInfo?.hasCheckBox ?? true;
    gridInitInfo.hasRowHeaderCheckBox = gridInitInfo?.hasRowHeaderCheckBox ?? false;
    gridInitInfo.showCheckAll = gridInitInfo?.showCheckAll ?? false;
    gridInitInfo.hasDisableCheck = gridInitInfo?.hasDisableCheck ?? true;
    gridInitInfo.canEdit = gridInitInfo?.canEdit ?? true;
    gridInitInfo.canAreaInfo = gridInitInfo?.canAreaInfo ?? false;
    gridInitInfo.allowPinning = gridInitInfo?.allowPinning ?? false;

    gridInitInfo.useMerge = gridInitInfo?.useMerge ?? false;
    gridInitInfo.useMergeType = gridInitInfo?.useMergeType ?? null;
    gridInitInfo.useMergeKey = gridInitInfo?.useMergeKey ?? "";

    gridInitInfo.useContextMenu = gridInitInfo?.useContextMenu ?? false;
    gridInitInfo.useDefaultEditedEvent = gridInitInfo?.useDefaultEditedEvent ?? true;
    gridInitInfo.isReadOnly = gridInitInfo?.isReadOnly ?? false;
    gridInitInfo.isPopup = gridInitInfo?.isPopup ?? false;

    gridInitInfo.hasRelationGrid = gridInitInfo?.hasRelationGrid ?? false;
    gridInitInfo.rowChangeFlagObjct = gridInitInfo?.rowChangeFlagObjct ?? null;

    gridInitInfo.hasReadOnlyCombo = gridInitInfo?.hasReadOnlyCombo ?? false;
    gridInitInfo.readOnlyComboList = gridInitInfo?.readOnlyComboList ?? [];

    gridInitInfo.autoRowSelectedStyle = gridInitInfo?.autoRowSelectedStyle ?? true;

    gridInitInfo.editApplyStyleColumns =
      gridInitInfo?.editApplyStyleColumns ?? null;
    gridInitInfo.autoEditMode = gridInitInfo?.autoEditMode ?? false;

    gridInitInfo.editableNewRow = gridInitInfo?.editableNewRow ?? [];

    gridInitInfo.hasEditableCss = gridInitInfo?.hasEditableCss ?? false;

    gridInitInfo.requiredColumns = gridInitInfo?.requiredColumns ?? [];

    gridInitInfo.hasShowNumbering = gridInitInfo?.hasShowNumbering ?? false; //row Header 넘버링
    gridInitInfo.boolleanCheckBox = gridInitInfo?.boolleanCheckBox ?? ""; //BooleanCheck 사용
    gridInitInfo.isRequiredDisplayCheck = gridInitInfo?.isRequiredDisplayCheck ?? true; //ColumHeader 필수 표시 여부
    //if (gridInitInfo.isReadOnly){gridObject.customeIsReadOnly = true}
    gridInitInfo.isCellCopyOnly = gridInitInfo?.isCellCopyOnly ?? false;
    gridInitInfo.isRemoveCopiedNewLine = gridInitInfo?.isRemoveCopiedNewLine ?? true;
    // 컬럼만 표시
    gridObject.headersVisibility = "Column"; // All None, Column Row
    // 탭으로 셀이동
    gridObject.keyActionTab = gridInitInfo?.keyActionTab ?? "Cycle";
    gridObject.keyActionEnter = gridInitInfo?.keyActionEnter ?? "Cycle";

    // 셀 선택 표시
    gridObject.showMarquee = true;
    gridObject.imeEnabled = true;

    gridObject.columnHeaders.rows.defaultSize = 36;

    gridObject.addEventListener(gridObject.hostElement, "input", (e) => {
      const inputElement = e.target;
      const value = inputElement.value;
      if (/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/.test(value)) {
        inputElement.value = value.replace(/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/g, ""); // 한글 문자 제거
      }
    });

    gridObject.formatItem.addHandler((s, e) => {
      if (e.panel === s.columnHeaders) {
        let splitTitle = e.cell.innerHTML.split("&nbsp;");

        let splitLenth = splitTitle.length;
        let resultTitle = [];

        // isRequired 항목 처리
        let isRequestCheck = "";
        if (gridInitInfo.isRequiredDisplayCheck) {
          if (e.getColumn(e.col)?.isRequired == true) {
            isRequestCheck = `<span class="isRequired" style="color: red; font-weight: bold; margin-right: 3px">*</span>`;
          }
        }

        for (let titleLoop = 0; titleLoop < splitLenth; titleLoop++) {
          if (titleLoop === 0) {
            if (
              gridInitInfo.requiredColumns.length > 0 &&
              gridInitInfo.requiredColumns.indexOf(
                e?.getColumn(e.col)?.binding ?? ""
              ) !== -1
            ) {
              resultTitle.push(
                `<span class='txt-title txt-imp'>${isRequestCheck}${splitTitle[0]}</span>`
              );
            } else {
              resultTitle.push(
                `<span class='txt-title'>${isRequestCheck}${splitTitle[0]}</span>`
              );
            }
          } else {
            resultTitle.push(`${isRequestCheck}${splitTitle[titleLoop]}`);
          }
        }

        e.cell.innerHTML = resultTitle.join(" "); // ComUtils.UnEscapeHtml(eventObject.cell.innerHTML); //eventObject.cell.textContent;
      }
    });

    // 필터 설정
    if (gridInitInfo.useFilter) {
      gridObject.sortingColumn.addHandler((s, e) => {
        if (s.columns[e.col].currentSort === "-") {
          e.cancel = true;
          s.collectionView.sortDescriptions.clear();
        }
      });
    }

    // Cell 복사만 가능
    if (gridInitInfo.isCellCopyOnly) {
      gridObject.copying.addHandler((s, e) => {
        //기본 동작 취소
        e.cancel = true;
        //현재 선택된 셀 값 가져오기
        let selectedCellData = s.getCellData(e.row, e.col);
        if (selectedCellData) {
          wjCore.Clipboard.copy(selectedCellData.toString());
        }
      });
    }

    // Cell 복사 후 줄넘김 제거
    if (gridInitInfo.isRemoveCopiedNewLine) {
      gridObject.copying.addHandler((s, e) => {
        const selectedRanges = gridObject.selectedRanges[0];
        // selection 범위가 단일셀이면 데이터만 복사하고, 범위가 여러셀이면 header도 함께 복사
        let isHeaderCopy = (selectedRanges.topRow === selectedRanges.bottomRow && selectedRanges.leftCol === selectedRanges.rightCol) ? false : true;
        let text = s?.getClipString(null, false, isHeaderCopy, false)?.replaceAll("\r", " ");

        if (isHeaderCopy && s.columnHeaders.rows.length > 1) {
          let lines = text.split('\n');
          let len = s.columnHeaders.rows.length - 1;

          for (let i = 0; i <= len; i++) {
            let col = lines[i].split('\t');
            if (i < len) {
              let nextRowCol = lines[i + 1].split('\t');
              for (let j = 0; j < col.length; j++) {
                if (col[j] !== nextRowCol[j]) {
                  nextRowCol[j] = `${col[j]} > ${nextRowCol[j]}`
                }
              }
              lines[i + 1] = nextRowCol.join('\t');
            }
          }

          lines.splice(0, len);

          text = lines.join('\n');
        }

        wjCore.Clipboard.copy(text);
        e.cancel = true;
      })
    }

    // Merge 순차 넘버링 처리
    // 이전 행 번호 - 현재 행 번호 차이가 1보다 크면 이전 행 번호에 1을 더한 값을 출력
    // let prevRowNum = 0;
    // function calculateRowNum(row) {
    //   let rowNum = row + 1;
    //   if (rowNum - prevRowNum > 1) {
    //     rowNum = prevRowNum + 1;
    //   }
    //   return rowNum;
    // }

    // rowHeader 넘버링 추가
    if (gridInitInfo.hasShowNumbering) {
      gridObject.headersVisibility = "All"; // All None, Column Row
      gridObject.formatItem.addHandler(function (s, e) {
        if (e.panel == s.topLeftCells) {
          e.cell.innerHTML = '<span class="txt-title">No</span>';
        }
        if (e.panel === s.rowHeaders) {
          let element = e.cell;
          element.innerHTML = e.row + 1;

          // Merge 순차 넘버링 처리
          // 이전 행 번호에서 1이 더해진 값을 innerHTML로 설정
          // let rowNumber = calculateRowNum(e.row);
          // element.innerHTML = rowNumber;
          // prevRowNum = rowNumber;
        }
      });
    }

    if (gridInitInfo.hasCheckBox) {

      if (!gridInitInfo.hasShowNumbering) {
        gridObject.headersVisibility = "All"; // All None, Column Row
      }

      let goCheck1 = true;
      gridObject.formatItem.addHandler(function (s, e) {
        if (gridObject.columns.length > 0) {
          if (goCheck1) {
            // 전체 선택 표시
            let col = gridObject.getColumn("isChecked");
            if (gridInitInfo.showCheckAll) {
              gridObject.customCheckBoxChecked = false;
              new Selector(gridObject, {
                itemChecked: () => {
                  //   this.selectedItems = gridObject.rows.filter((r) => r.isSelected);
                },
                column: col,
              });
            } else {
              new Selector(gridObject, {
                itemChecked: () => {
                  //   this.selectedItems = gridObject.rows.filter((r) => r.isSelected);
                },
                column: col,
                showCheckAll: false,
              });
            }

            goCheck1 = false;
          }
        }
      });

      // 전체 선택 중 수정 불가 있는 경우
      if (gridInitInfo.hasDisableCheck) {
        gridObject.hasDisableSelector = true;
        setTimeout(() => {
          gridObject.formatItem.addHandler(function (s, e) {
            let binding = e.getColumn().binding;
            if (
              e.panel === s.cells &&
              binding === "isChecked" &&
              s.rows[e.row]?.dataItem?.uiFlag !== "I"
            ) {
              let element = e.cell;
              let checkboxElement = element.querySelector(
                "input[type='checkbox']"
              );
              if (checkboxElement) {
                checkboxElement.disabled = true;
              }
            }
          });
        }, 1500);
      }

    } else {

      // rowHeader에 바로 Check Box 사용
      // 체크 박스 있는 경우
      if (!gridInitInfo.hasShowNumbering && gridInitInfo.hasRowHeaderCheckBox) {
        // 체크 박스 이므로  헤더 표시를 All로 변경
        gridObject.headersVisibility = "All"; // All None, Column Row

        if (gridInitInfo.showCheckAll) {
          gridObject.customCheckBoxChecked = false;
          new Selector(gridObject);
        } else {
          new Selector(gridObject, { showCheckAll: false });
        }

        // 전체 선택 중 수정 불가 있는 경우
        if (gridInitInfo.hasDisableCheck) {
          gridObject.hasDisableSelector = true;
          gridObject.formatItem.addHandler(function (s, e) {
            if (
              e.panel === s.rowHeaders &&
              s.rows[e.row].dataItem.uiFlag !== "I"
            ) {
              let element = e.cell;
              let checkboxElement = element.querySelector(
                "input[type='checkbox']"
              );
              if (checkboxElement) {
                checkboxElement.disabled = true;
              }
            }
          });
        }
      }
    }

    // BoolenChecker 체크 박스 사용 (BooleanChecker - 속도?)
    if (gridInitInfo.boolleanCheckBox != "") {
      let goCheck2 = true;
      gridObject.formatItem.addHandler(function (s, e) {
        if (gridObject.columns.length > 0) {
          if (goCheck2) {
            let tmpCol = gridObject.columns[gridInitInfo.boolleanCheckBox];
            new BooleanChecker(tmpCol, {
              itemChecked: (s, e) => { },
            });
            goCheck2 = false;
          }
        }
      });
    }

    // 수정 표시
    if (gridInitInfo.canEdit) {
      gridObject.cellEditEnded.addHandler((s, e) => {
        if (gridObject?.collectionView) {
          gridObject.collectionView.editItem(
            gridObject.collectionView.items[s.selection.row]
          );
          gridObject.collectionView.commitEdit();
        }
      });

      if (
        (gridInitInfo?.editApplyStyleColumns ?? null) !== null &&
        (gridInitInfo?.editApplyStyleColumns?.length ?? 0) > 0
      ) {
        new GridEditedByColumnId(
          gridObject,
          "cell-changed",
          gridInitInfo.editApplyStyleColumns
        );
      } else {
        new GridEdited(gridObject, "cell-changed");
      }
      if (gridInitInfo?.autoEditMode) {
        gridObject.selectionChanged.addHandler((s, e) => {
          let index = s.selection.col;
          let col = index > -1 ? s.columns[index] : null;
          if (col && !col.isReadOnly && col.dataType !== DataType.Boolean) {
            setTimeout(() => {
              s.startEditing(false);
            }, 50); // let the grid update first
          }
        });

        gridObject.gotFocus.addHandler((s, e) => {
          let index = s.selection.col;
          let col = index > -1 ? s.columns[index] : null;
          if (col && !col.isReadOnly && col.dataType !== DataType.Boolean) {
            setTimeout(() => {
              s.startEditing(false);
            }, 50); // let the grid update first
          }
        });
      }
    }

    // 그리드 읽기 전용
    if (gridInitInfo.isReadOnly) {
      gridObject.isReadOnly = true;
    }

    // 그리드 영역 정보 표시
    if (gridInitInfo.canAreaInfo) {
      ComUtils.GridAreaInfo(gridObject);
    }

    // 그리드 contextMenu 사용
    if (gridInitInfo.useContextMenu) {
      new GridContextMenu(gridObject);
    }

    // 셀 병합 사용  allowMerging:true 속성이 필요함
    if (gridInitInfo.useMerge) {
      let mergeKey = null;
      let mergeType = gridInitInfo?.useMergeType ?? "DEFAULT";
      switch (mergeType) {
        case "DEFAULT":
          gridObject.mergeManager = new GridUserMerge();
          break;
        case "KEY":
          mergeKey = (gridInitInfo?.useMergeKey ?? "").replace(/ /gi, "");
          if (mergeKey.length > 0) {
            gridObject.customMergeKey = (gridInitInfo?.useMergeKey ?? "")
              .replace(/ /gi, "")
              .split(",");
            gridObject.mergeManager = new GridUserMergeByKey();
          } else {
            gridObject.mergeManager = new GridUserMerge();
          }
          break;
        case "SELECTOR":
          mergeKey = (gridInitInfo?.useMergeKey ?? "").replace(/ /gi, "");
          if (mergeKey.length > 0) {
            gridObject.customMergeKey = (gridInitInfo?.useMergeKey ?? "")
              .replace(/ /gi, "")
              .split(",");
            gridObject.mergeManager = new GridUserMergeSelector();
          }
          break;
        default:
          break;
      }

      gridObject.scrollPositionChanged.addHandler(function (s, e) {
        gridObject.refresh();
      });
    }

    // 컬럼 틀고정
    if (gridInitInfo.allowPinning) {
      gridObject.allowPinning = "ColumnRange";
      if (gridInitInfo.frozenColumns) {
        gridObject.frozenColumns = gridInitInfo.frozenColumns;
      }
    }

    if (
      gridInitInfo.hasReadOnlyCombo &&
      gridInitInfo.readOnlyComboList.length > 0
    ) {
      gridObject.formatItem.addHandler(function (s, e) {
        if (e.panel !== s.cells) {
          return;
        }

        let applyColumnList = gridInitInfo.readOnlyComboList;
        let col = s.columns[e.col];
        let colName = col.binding; // binding
        let flag = s?.rows[e.row]?.dataItem?.uiFlag ?? "N";

        if (flag !== "I" && applyColumnList.indexOf(colName) > -1) {
          addClass(e.cell, "select-disabled");
        }
      });
    }

    // 6 : 다중영역, 3 : 행
    gridObject.selectionMode = gridInitInfo.selectionMode;
    if (gridObject.selectionMode === 6) {
      if (gridInitInfo.autoRowSelectedStyle) {
        gridObject.formatItem.addHandler(function (s, e) {
          if (e.panel !== s.cells) {
            return;
          }
          setTimeout(() => {
            if (
              (s?.selection?.row ?? -1) !== -1 &&
              (s?.selection?.isSingleCell ?? false)
            ) {
              let _changingRowIndex = s.selection.row;
              let max = s?.columns?.length ?? 0;

              let rowDataInfo = e.getRow();
              let uiFlag = rowDataInfo?.dataItem?.uiFlag ?? "N";

              for (let i = 0; i < max; i++) {
                let el = s?.cells?.getCellElement(_changingRowIndex, i) ?? null;

                let bindingName = e?.getColumn(i)?.binding ?? "";
                let col =
                  s.selection.col > -1 ? s.columns[s.selection.col] : null;
                //let otherCol = s?.columns[i]?? null;

                if (el) {
                  if (s.isReadOnly) {
                    wjCore.addClass(el, "wj-state-multi-selected");
                  } else {
                    if (s.selection.col !== i) {
                      wjCore.addClass(el, "wj-state-multi-selected");
                      /*
                                            if (otherCol && !(otherCol?.isReadOnly??false) && col.dataType !== DataType.Boolean) {
                                                if(gridInitInfo.editableNewRow.indexOf(bindingName) > -1 && uiFlag ==="I"){
                                                    wjCore.addClass(el, "cell-modify");
                                                }
                                                if(gridInitInfo.editableNewRow.indexOf(bindingName) === -1){
                                                    wjCore.addClass(el, "cell-modify");
                                                }
                                            }
                                            */
                    } else {
                      if (
                        col &&
                        !col.isReadOnly &&
                        col.dataType !== DataType.Boolean
                      ) {
                        if (
                          uiFlag !== "I" &&
                          gridInitInfo.editableNewRow.indexOf(bindingName) > -1
                        ) {
                          wjCore.addClass(el, "wj-state-multi-selected");
                        } else {
                          wjCore.removeClass(el, "wj-state-multi-selected");
                          //   wjCore.removeClass(el, "cell-modify");
                        }
                      } else {
                        wjCore.addClass(el, "wj-state-multi-selected");
                      }
                    }
                  }
                }
              }
            }
          }, 50);
        });

        gridObject.selectionChanging.addHandler(function (s, e) {
          if (s.selection.topRow !== -1 && s.selection.isSingleCell) {
            //console.log("selectionChanging");
            let sel = s.selection;
            let _changingRowIndex = sel.topRow;

            let rowDataInfo = s.rows[_changingRowIndex];
            let uiFlag = rowDataInfo?.dataItem?.uiFlag ?? "N";

            let max = s?.columns?.length ?? 0;
            for (let i = 0; i < max; i++) {
              let el = s?.cells?.getCellElement(_changingRowIndex, i) ?? null;
              let col = s?.columns[i] ?? null;
              //let bindingName = e?.getColumn(i)?.binding??""
              let bindingName = s?.columns[i]?.binding ?? "";
              if (el) {
                wjCore.removeClass(el, "wj-state-multi-selected");
                wjCore.removeClass(el, "wj-state-multi-no-edit-selected");

                if (!s.isReadOnly) {
                  if (gridInitInfo?.hasEditableCss ?? false) {
                    if (
                      col &&
                      !col.isReadOnly &&
                      col.dataType !== DataType.Boolean
                    ) {
                      if (
                        (gridInitInfo.editableNewRow.indexOf(bindingName) >
                          -1 &&
                          uiFlag === "I") ||
                        gridInitInfo.editableNewRow.indexOf(bindingName) === -1
                      ) {
                        wjCore.addClass(el, "cell-modify");
                      }
                    }
                  }
                }
              }
            }
          }
        });

        gridObject.selectionChanged.addHandler(function (s, e) {
          if (s.selection.topRow !== -1 && s.selection.isSingleCell) {
            let sel = s.selection;
            let _changingRowIndex = sel.topRow;

            let max = s?.columns?.length ?? 0;
            let rowDataInfo = e.getRow();
            let uiFlag = rowDataInfo?.dataItem?.uiFlag ?? "N";

            for (let i = 0; i < max; i++) {
              let el = s?.cells?.getCellElement(_changingRowIndex, i) ?? null;

              let bindingName = e?.getColumn(i)?.binding ?? "";
              let col =
                s.selection.col > -1 ? s.columns[s.selection.col] : null;
              //let otherCol = s?.columns[i]?? null;
              if (el) {
                if (s.isReadOnly) {
                  wjCore.addClass(el, "wj-state-multi-selected");
                } else {
                  if (s.selection.col !== i) {
                    wjCore.addClass(el, "wj-state-multi-selected");
                    /*
                                        if (otherCol && !(otherCol?.isReadOnly??false) && col.dataType !== DataType.Boolean) {
                                            if(gridInitInfo.editableNewRow.indexOf(bindingName) > -1 && uiFlag ==="I"){
                                                wjCore.addClass(el, "cell-modify");
                                            }
                                            if(gridInitInfo.editableNewRow.indexOf(bindingName) === -1){
                                                wjCore.addClass(el, "cell-modify");
                                            }
                                        }
                                        */
                  } else {
                    if (
                      col &&
                      !col.isReadOnly &&
                      col.dataType !== DataType.Boolean
                    ) {
                      if (
                        uiFlag !== "I" &&
                        gridInitInfo.editableNewRow.indexOf(bindingName) > -1
                      ) {
                        wjCore.addClass(el, "wj-state-multi-selected");
                      } else {
                        wjCore.removeClass(el, "wj-state-multi-selected");
                        // wjCore.removeClass(el, "cell-modify");
                      }
                    } else {
                      wjCore.addClass(el, "wj-state-multi-selected");
                    }
                  }
                }
              }
            }
          }
        });
      }
    }
    if (!gridInitInfo.isPopup) {
      // 헤더 고정
      gridObject.stickyHeaders = true;
    }
  }
};

ComUtils.tryToNumber = (v) => {
  let t = 0;
  try {
    t = parseFloat(v, 10);
    if (isNaN(String(t))) {
      return 0;
    } else {
      return t;
    }
  } catch (ex) {
    return 0;
  }
};

ComUtils.FromDateAddDayAbs = (date, days) => {
  // date는 문자열로 받는다 ex, '2020-10-15'
  let result = new Date(ComUtils.fromUTC(new Date(date)));
  result.setDate(result.getDate() + days);
  return result;
};

ComUtils.nowAddDayByMoment = (days) => {
  return new Date(moment().add(days, "days"));
};

ComUtils.FromDateAddDay = (date, days) => {
  // date는 문자열로 받는다 ex, '2020-10-15'
  let result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};
// 날짜를 YYYY-MM-DD로 변환
ComUtils.DateAdd = (days) => {
  // date는 문자열로 받는다 ex, '2020-10-15'
  let result = new Date();
  result.setDate(result.getDate() + days);
  return result;
};

// 날짜를 YYYY-MM-DD로 변환
ComUtils.DateToInteger = (dt) => {
  let newDt = null;
  if (!isDate(dt)) {
    let tmpValue = parseInt(dt, 10);
    if ((tmpValue + "").length === 8) {
      return tmpValue;
    }
  }
  // const myDate = typeof dt === "number" ? new Date(dt) : dt;

  if (typeof dt === "object") {
    newDt = new Date(dt);
  } else {
    newDt = new Date(`${dt} 00:00:00`);
  }

  //
  let dtString = newDt.getFullYear() + "";

  // 월
  if (newDt.getMonth() + 1 < 10) {
    dtString += "0";
    dtString += newDt.getMonth() + 1 + "";
  } else {
    dtString += newDt.getMonth() + 1 + "";
  }
  // 일
  if (newDt.getDate() < 10) {
    dtString += "0";
    dtString += newDt.getDate() + "";
  } else {
    dtString += newDt.getDate() + "";
  }

  return parseInt(dtString, 10);
  /* }
    else{
        return parseInt(dt.replace(/\-/, "")   , 10);
    } */
};

// 날짜를 YYYY-MM-DD로 변환2
ComUtils.DateToInteger2 = (dt) => {
  if (!isDate(dt)) {
    dt = new Date(ComUtils.fromUTC(new Date(dt)));
    // dt = new Date(`${dt} 00:00:00`);
  }
  //
  let dtString = dt.getFullYear() + "";

  // 월
  if (dt.getMonth() + 1 < 10) {
    dtString += "0";
    dtString += dt.getMonth() + 1 + "";
  } else {
    dtString += dt.getMonth() + 1 + "";
  }
  // 일
  if (dt.getDate() < 10) {
    dtString += "0";
    dtString += dt.getDate() + "";
  } else {
    dtString += dt.getDate() + "";
  }

  return parseInt(dtString, 10);
  /* }
    else{
        return parseInt(dt.replace(/\-/, "")   , 10);
    } */
};

// 날짜를 YYYY-MM-DD로 변환
ComUtils.DateToString = (dt) => {
  let dtString = dt.getFullYear() + "-";

  // 월
  if (dt.getMonth() + 1 < 10) {
    dtString += "0";
    dtString += dt.getMonth() + 1;
    dtString += "-";
  } else {
    dtString += dt.getMonth() + 1;
    dtString += "-";
  }
  // 일
  if (dt.getDate() < 10) {
    dtString += "0";
    dtString += dt.getDate();
  } else {
    dtString += dt.getDate();
  }

  return dtString;
};

ComUtils.DateDiffMonth = (dt, dt2) => {
  let sYear = dt.substring(0, 4);
  let sMonth = dt.substring(4, 6);

  let eYear = dt2.substring(0, 4);
  let eMonth = dt2.substring(4, 6);
  return (eYear - sYear) * 12 + (eMonth - sMonth);
};

ComUtils.DateDiff = (dt, dt2) => {
  let count = 0;
  let curDate = new Date(dt.getTime());
  curDate.setHours(0, 0, 0, 0);
  while (curDate <= dt2) {
    count++;
    curDate.setDate(curDate.getDate() + 1);
  }
  return count;
};

ComUtils.DateDiffDays = (dt, dt2) => {
  console.log(dt, dt2)
  let dateOfFromDate = Number(dt.split("-")[2]);
  let dateOfToDate = Number(dt2.split("-")[2]);

  console.log(dateOfFromDate, dateOfToDate, (dateOfFromDate - dateOfToDate))
  let daysDiff = dateOfFromDate - dateOfToDate;

  return daysDiff;
};

// working Date 비교
ComUtils.WorkDateDiff = (dt, dt2) => {
  let count = 0;
  let curDate = new Date(dt.getTime());
  curDate.setHours(0, 0, 0, 0);
  while (curDate <= dt2) {
    let dayOfWeek = curDate.getDay();
    if (dayOfWeek !== 0 && dayOfWeek !== 6) count++;
    curDate.setDate(curDate.getDate() + 1);
  }
  return count;
};

ComUtils.fromUTC = (datetime) => {
  const myDate = typeof datetime === "number" ? new Date(datetime) : datetime;

  if (!myDate || typeof myDate.getTime !== "function") {
    return 0;
  }

  const getUTC = myDate.getTime();
  const offset = myDate.getTimezoneOffset() * 60000; // It's in minutes so convert to ms
  return getUTC + offset; // UTC + OFFSET
};

ComUtils.toUTC = (datetime) => {
  const myDate = typeof datetime === "number" ? new Date(datetime) : datetime;

  if (!myDate || typeof myDate.getTime !== "function") {
    return 0;
  }

  const getUTC = myDate.getTime();
  const offset = myDate.getTimezoneOffset() * 60000; // It's in minutes so convert to ms
  return getUTC - offset; // UTC - OFFSET
};

// working Date 비교
ComUtils.WorkDateAfterDate = (dt, diffDays) => {
  let count = 0;
  let curDate = new Date(dt);
  curDate.setHours(0, 0, 0, 0);
  while (true) {
    curDate.setDate(curDate.getDate() + 1);

    let dayOfWeek = curDate.getDay();
    if (dayOfWeek !== 0 && dayOfWeek !== 6) count++;

    if (count >= diffDays) {
      break;
    }
  }

  return curDate;
};

// working Date 1 비교
ComUtils.WorkDateAfterDate1 = (dt, diffDays) => {
  let count = 0;
  let curDate = new Date(dt);
  curDate.setHours(0, 0, 0, 0);
  if (diffDays > 0) {
    while (true) {
      curDate.setDate(curDate.getDate() + 1);
      let dayOfWeek = curDate.getDay();
      if (dayOfWeek !== 0 && dayOfWeek !== 6) count++;

      if (count >= diffDays) {
        break;
      }
    }
  }
  return curDate;
};

ComUtils.dateToSubsidaryForamt = (date) => {
  if (date && date.length > 0) {
    let subsidiaryDateFormat = sessionStorage
      .getItem("actorDateFrmt")
      .toString();
    return moment(new Date(date) ?? new Date()).format(
      subsidiaryDateFormat.toUpperCase()
    );
  }
};

ComUtils.makeMenuTreeSubItems = (originalData, pObject, levelCount) => {
  let newLevel = levelCount + 1;

  let t = originalData?.filter((el) => el.rootMenuId === pObject.menuId);

  if (t !== undefined && t !== null && t.length > 0) {
    t.sort(function (a, b) {
      return parseFloat(a.sortNo) - parseFloat(b.sortNo);
    });

    let loopMax = t.length;
    for (let i = 0; i < loopMax; i++) {
      let _subMenuNode = t[i];

      if (pObject.hasOwnProperty("parentNamePath")) {
        _subMenuNode.parentNamePath =
          pObject.parentNamePath + " > " + _subMenuNode.menuName;
      } else {
        _subMenuNode.parentNamePath =
          pObject.menuName + " > " + _subMenuNode.menuName;
      }

      if (pObject.hasOwnProperty("parentPath")) {
        _subMenuNode.parentPath = pObject.parentPath + "/" + pObject.menuId;
      } else {
        _subMenuNode.parentPath = pObject.menuId + "";
      }

      if (_subMenuNode?.subsdrUseFlag) {
        if (_subMenuNode.subsdrUseFlag && _subMenuNode.subsdrUseFlag === "Y") {
          _subMenuNode.subsdrUseFlagCheck = true;
        } else {
          _subMenuNode.subsdrUseFlagCheck = false;
        }
      }

      _subMenuNode.currentLevel = newLevel + 1;
      _subMenuNode.newDisplaySeq = i + 1;
      _subMenuNode.newMaxSeq = loopMax;

      ComUtils.makeMenuTreeSubItems(originalData, _subMenuNode, newLevel);

      pObject.items = pObject.items || [];
      pObject.items.push(_subMenuNode);

      //pObject["depth" + newLevel] = pObject["depth" + newLevel]||[];
      //pObject["depth" + newLevel].push(t[i]);
    }
  }
  return null;
};

ComUtils.makeMenuTree = (originalData) => {

  try {
    let firstNodes = originalData?.filter((el) => el.menuLevel === 1);

    firstNodes.sort(function (a, b) {
      return parseFloat(a.sortNo) - parseFloat(b.sortNo);
    });
    let levelCount = 0;
    let maxLength = firstNodes.length;
    for (let i = 0; i < maxLength; i++) {
      let _menuNode = firstNodes[i];

      if (_menuNode?.subsdrUseFlag) {
        if (_menuNode.subsdrUseFlag && _menuNode.subsdrUseFlag === "Y") {
          _menuNode.subsdrUseFlagCheck = true;
        } else {
          _menuNode.subsdrUseFlagCheck = false;
        }
      }
      ComUtils.makeMenuTreeSubItems(originalData, _menuNode, levelCount);
    }
    return firstNodes;
  } catch (ex) {
    console.log("ex:", ex.message);
  }

};

ComUtils.treeCheckChangeSubItem = (subData, flag) => {
  if (subData.hasOwnProperty("items")) {
    let maxLength = subData.items.length;

    for (let i = 0; i < maxLength; i++) {
      subData.items[i].subsdrUseFlagCheck = flag;
      subData.items[i]["uiFlag"] = "U";

      if (subData.items[i].hasOwnProperty("items")) {
        ComUtils.treeCheckChangeSubItem(subData.items[i], flag);
      }
    }
  }
};

ComUtils.treeCheckChange = (treeData, parentList, index) => {
  let max = parentList.length;
  for (let i = index; i < max; i++) {
    let t = treeData.filter((el) => el.menuCode === parentList[i]);

    if (t !== undefined && t !== null && t.length > 0) {
      let subMax = t.length;
      for (let j = 0; j < subMax; j++) {
        t[j]["uiFlag"] = "U";
        t[j].subsdrUseFlagCheck = true;

        if (t[j].hasOwnProperty("items")) {
          ComUtils.treeCheckChange(t[j].items, parentList, index + 1);
        }
      }
    }
  }
};

ComUtils.treeItemsByLevel = (treeData, parentList, index, resultArray) => {
  if (index < parentList.length) {
    let item = treeData.filter((el) => el.menuCode === parentList[index]);
    if (item?.length > 0 && item[0]?.items) {
      resultArray.push(item[0]);
      ComUtils.treeItemsByLevel(
        item[0].items,
        parentList,
        index + 1,
        resultArray
      );
    }
  }
};

ComUtils.treeUnCheckChange = (treeData, parentList, flag, index) => {
  let depthItems = [];

  ComUtils.treeItemsByLevel(treeData, parentList, 0, depthItems);

  if (depthItems && depthItems.length > 0) {
    let maxCnt = depthItems.length - 1;
    for (let i = maxCnt; i >= 0; i--) {
      let hasSubCheck = depthItems[i].items.filter(
        (el) => el.subsdrUseFlagCheck === true
      );
      if (hasSubCheck === 0) {
        depthItems[i].subsdrUseFlagCheck = false;
      }
    }
  }
};

ComUtils.treeToArray = (treeData, targetArray) => {
  let tmpArray = _.cloneDeep(treeData);

  let max = tmpArray.length;
  for (let i = 0; i < max; i++) {
    let t = tmpArray[i];

    if (t !== undefined && t !== null) {
      let subItems = null;
      if (t.hasOwnProperty("items")) {
        subItems = _.cloneDeep(t.items);
      }
      delete t.items;
      if (
        t.hasOwnProperty("subsdrUseFlagCheck") &&
        t.hasOwnProperty("uiFlag") &&
        t.uiFlag === "U"
      ) {
        t.subsdrUseFlag = t.subsdrUseFlagCheck ? "Y" : "N";
        targetArray.push(t);
      }

      if (subItems !== undefined && subItems !== null) {
        ComUtils.treeToArray(subItems, targetArray);
      }
    }
  }

  //return targetArray;
};

// Tree control에서 아이템 찾기
ComUtils.FindTreeItems = (items, str, valueField, returnValues) => {
  for (let i = 0; items && i < items.length; i++) {
    if (str.indexOf(items[i][valueField]) !== -1) {
      returnValues.push(items[i]);
    }

    let result = ComUtils.FindTreeItems(
      items[i].items,
      str,
      valueField,
      returnValues
    );
    if (result) {
      returnValues.push(result);
    }
  }
};
// 숫자 3자리 콤마
ComUtils.NumberWithComma = (sValue) => {
  if (sValue) {
    return sValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  } else {
    return "0";
  }
};

// 숫자 3자리 콤마
ComUtils.NumberWithComma = (sValue, n) => {
  if (sValue) {
    return (Math.round(sValue * 100) / 100).toFixed(n);
    // return sValue.toString().replace(/\B(?=(\d{n})+(?!\d))/g, ",");
  } else {
    return "0";
  }
};
// email 형식 체크
ComUtils.emailInvalidate = (values) => {
  // let msg = ''
  //if (!values) {
  //  msg = 'Required'
  //} else
  if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values)) {
    return true; // msg = 'Invalid email address'
  }
  return false;
};

// html 태그 변환 표시 html 그대로 출력을 위해서 사용
ComUtils.UnEscapeHtml = (str) => {
  const sanitizedHtmlContent = DOMPurify.sanitize(str);
  if (sanitizedHtmlContent) {
    return sanitizedHtmlContent
      .replace(/&amp;/g, "&")
      .replace(/&lt;/g, "<")
      .replace(/&gt;/g, ">")
      .replace(/&quot;/g, '"')
      .replace(/&#039;/g, "'")
      .replace(/&#39;/g, "'");
  }
  return "";
};

ComUtils.escapeToHtml = (str) => {
  if (str) {
    return str
      .replace(/\&/g, "&amp;")
      .replace(/\</g, "&lt;")
      .replace(/\>/g, "&gt;")
      .replace(/\"/g, "&quot;")
      .replace(/\'/g, "&#039;")
      .replace(/\'/g, "&#39;");
  }
  return "";
};

// 문자열 Byte 길이
ComUtils.StringByteLength = (string) => {
  if (string) {
    return string.replace(/[\0-\x7f]|([0-\u07ff]|(.))/g, "$&$1$2").length;
  } else {
    return 0;
  }
};

// 그리드 영역 count / sum / avg 표시 이벤트 등록
ComUtils.GridAreaInfo = (gridObject) => {
  gridObject.addEventListener(gridObject.hostElement, "mouseup", (ev) => {
    function showAreaInfo(sender) {
      function aggregateRange(tally, grid, ranges, index) {
        let rng = ranges[index];

        if (rng.topRow === rng.bottomRow && rng.leftCol === rng.rightCol) {
          return null;
        } else {
          for (let r = rng.topRow; r <= rng.bottomRow; r++) {
            for (let c = rng.leftCol; c <= rng.rightCol; c++) {
              // account for overlapping ranges
              let overlapped = false;
              for (let i = 0; i < index && !overlapped; i++) {
                let rng = ranges[i];
                if (rng.contains(r, c)) {
                  overlapped = true;
                }
              }

              // tally non-overlapped cells
              if (!overlapped) {
                if (grid.getColumn(c).visible) {
                  let data = grid.getCellData(r, c, false);

                  let values = parseFloat(
                    ComUtils.tryToNumber(data).toFixed(2),
                    10
                  );
                  if (isNumber(data) && isNumber(values)) {
                    // handle numbers
                    //if (data !== -999) {
                    tally.cnt++;
                    tally.sum += data;
                    //}
                  }

                  if (data !== "" && data !== null) {
                    // handle non-empty cells
                    tally.cntAll++;
                  }
                }
              }
            }
          }

          if (tally.cnt > 0) {
            tally.avg = tally.sum / tally.cnt;
          } else {
            tally.avg = 0;
          }

          //tally.avg = tally.cnt > 0 ? (tally.sum / tally.cnt).toFixed(2) : 0;
        }
      }

      let tally = { cnt: 0, cntAll: 0, sum: 0, avg: 0 };
      let ranges = sender.selectedRanges;

      for (let i = 0; i < ranges.length; i++) {
        aggregateRange(tally, sender, ranges, i)
      }

      return tally;
    }

    let ht = gridObject.hitTest(ev);
    let infoDiv = document.getElementById("areaInfoObject");
    let isAreaDisplay = false;
    if (ht.cellType === 1) {
      let areaData = showAreaInfo(gridObject);

      //cnt: 0, cntAll: 0, sum: 0, avg: 0
      if (areaData) {
        if (areaData.cntAll > 1) {
          //if (areaData.cnt > 1){
          //let msg = (areaData.cnt > 1)
          /*
                    let msg = (areaData.cntAll > 1)
                        ? glbz `<div class='grid_summary_popup'>
                                    <div class='grid_summary_popup_area'>
                                        <div class='grid_summary_popup_title'> Count </div>
                                        <div class='grid_summary_popup_cnt'> ${areaData.cntAll}:n0</div>
                                        <div class='grid_summary_popup_title'> Average </div>
                                        <div class='grid_summary_popup_avg'> ${areaData.avg}:g4</div>
                                        <div class='grid_summary_popup_title'>Sum</div>
                                        <div class='grid_summary_popup_sum'> ${areaData.sum}:g4</div>
                                    </div>
                                </div>`
                        : (areaData.cnt > 1)
                        ? glbz `<div> Count: <b>${areaData.cntAll}:n0</b></div>`
                        : 'Ready';
                    */
          //

          let msg =
            areaData.cntAll > 1
              ? `<div class='grid_summary_popup'>
                                    <div class='grid_summary_popup_area'>
                                        <div class='grid_summary_popup_title'> Count </div>
                                        <div class='grid_summary_popup_cnt'> ${wjCore.Globalize.formatNumber(areaData.cntAll, "n0")}</div>
                                        <div class='grid_summary_popup_title'> Average </div>
                                        <div class='grid_summary_popup_avg'> ${wjCore.Globalize.formatNumber(areaData.avg, "G2")}</div>
                                        <div class='grid_summary_popup_title'>Sum</div>
                                        <div class='grid_summary_popup_sum'> ${wjCore.Globalize.formatNumber(areaData.sum, "G2")}</div>
                                    </div>
                                </div>`
              : "Ready";

          infoDiv.innerHTML = msg;
          infoDiv.style.display = "block";

          //infoDiv.style.top = (ev.pageY-20)+'px';
          //infoDiv.style.left =(ev.pageX-20)+'px';

          infoDiv.style.top = ev.pageY + "px";
          infoDiv.style.left = ev.pageX + "px";

          infoDiv.style.zIndex = 100000;

          isAreaDisplay = true;
        }
      }
    }

    if (!isAreaDisplay) {
      infoDiv.style.display = "none";
    }
  });
};

//그리드 포커스
ComUtils.setGridFocus = (gridObject, row, colName) => {
  if (gridObject?.current) {
    let findRow = gridObject?.current?.columns?.find(el => el._binding._key == colName);
    gridObject?.current?.select(row ?? 0, findRow?.index);
    ComUtils.startEditing(gridObject?.current);
  }

}

// 그리드 컬럼 숨김처리
ComUtils.setGridHideColumn = (oArray, compare, rGroup) => {
  function getHideColumns(oArray, compare, rGroup) {
    let maxLoop = oArray.length;
    for (let i = 0; i < maxLoop; i++) {
      if (oArray[i].binding) {
        if (compare.indexOf("|" + oArray[i].binding + "|") === -1) {
          rGroup.push(oArray[i]);
        }
      }
      if (oArray[i].columns) {
        let subGroup = [];
        let newObject = {};
        Object.assign(newObject, oArray[i]);
        getHideColumns(newObject.columns, compare, subGroup);
        newObject.columns = subGroup;

        rGroup.push(newObject);
      }
    }
  }

  getHideColumns(oArray, compare, rGroup);
};

// 배열을 건수 별 분할
ComUtils.splitArrayData = (arr, size) => {
  let i,
    j,
    temparray = [],
    chunk = size;
  for (i = 0, j = arr.length; i < j; i += chunk) {
    temparray.push(arr.slice(i, i + chunk));
  }
  return temparray;
};

// 메세지 코드로 메세지 반환
//ComUtils.getMessage=(messageObject, messageCode, replaceList)=>{
ComUtils.getMessage = (messageObject, messageCode, replaceList) => {
  if (messageObject?.length > 0) {
    if (messageCode) {
      messageCode = messageCode.replace(/ /gi, "").toUpperCase();
      let d = messageObject.find((el) => el.msgCode === messageCode);

      if (d !== undefined && d !== null) {
        let msg = d.msgName.replace(/(?:\r\n|\r|\n|\\n)/g, '<br />');
        if (
          replaceList !== undefined &&
          replaceList !== null &&
          Array.isArray(replaceList)
        ) {
          for (let i = 0; i < replaceList.length; i++) {
            msg = msg.replace("{" + i + "}", replaceList[i]);
          }
        }
        return msg;
      } else {
        return "";
      }
    } else {
      return "";
    }
  } else {
    return "";
  }
};

ComUtils.getMessageReplace = (orgMessage, replaceList) => {
  let d = orgMessage;

  if (d !== undefined && d !== null) {
    if (
      replaceList !== undefined &&
      replaceList !== null &&
      Array.isArray(replaceList)
    ) {
      let msg = d;
      for (let i = 0; i < replaceList.length; i++) {
        msg = msg.replace("{#" + (i + 1) + "}", replaceList[i]);
      }
      return msg;
    } else {
      return d;
    }
  } else {
    return "";
  }
};

ComUtils.setComRandomKey = (setRandomKey) => {
  let now = new Date();
  setRandomKey(
    () =>
      now
        .toISOString()
        .replace("T", " ")
        .substring(0, 19)
        .replace(/[^0-9]/g, "") + now.getMilliseconds().toString()
  );
};

ComUtils.setRefRandomKey = () => {
  let now = new Date();
  return now.toISOString().replace("T", " ").substring(0, 19).replace(/[^0-9]/g, "") + now.getMilliseconds().toString();
};
// 네비게이션 스타일
ComUtils.setNaviSttyle = (menPath) => {
  let splitNaviPath = menPath.split(">");
  let naviList = [];
  for (let i = 0; i < splitNaviPath.length - 1; i++) {
    naviList.push('<span class="nav-1">' + splitNaviPath[i] + " &#62;</span>");
  }

  naviList.push(
    '<span class="nav-2">' + splitNaviPath[splitNaviPath.length - 1] + "</span>"
  );

  return naviList.join("");
};

ComUtils.XSSCheck = (str, level) => {
  if (level === undefined || level === 0) {
    str = str.replace(/\<|\>|\"|\'|\%|\;|\(|\)|\&|\+|\-/g, "");
  } else if (level !== undefined && level === 1) {
    str = str.replace(/\</g, "&lt;");
    str = str.replace(/\>/g, "&gt;");
  }
  return str;
};

ComUtils.startEditing = (flex) => {
  let index = flex.selection.col;
  let col = index > -1 ? flex.columns[index] : null;
  if (col && !col.isReadOnly && col.dataType !== DataType.Boolean) {
    flex.hostElement.addEventListener('keydown', (e) => {
      // 아래 방향키 누를 때 커서 위치 설정
      if (e.key === 'ArrowDown') {
        let cellEditorEl = flex.activeEditor;
        let value = cellEditorEl?.value;

        if (cellEditorEl?.value) {
          cellEditorEl.focus();
          cellEditorEl.value = '';
          cellEditorEl.value = value;
        }
      }
    });
    setTimeout(() => {
      flex.startEditing(true);
    }, 50);
  }
};

ComUtils.checkValidDate = (value) => {
  var result = true;
  try {
    var date = value.split("-");
    var y = parseInt(date[0], 10),
      m = parseInt(date[1], 10),
      d = parseInt(date[2], 10);

    var dateRegex =
      /^(?=\d)(?:(?:31(?!.(?:0?[2469]|11))|(?:30|29)(?!.0?2)|29(?=.0?2.(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00)))(?:\x20|$))|(?:2[0-8]|1\d|0?[1-9]))([-.\/])(?:1[012]|0?[1-9])\1(?:1[6-9]|[2-9]\d)?\d\d(?:(?=\x20\d)\x20|$))?(((0?[1-9]|1[012])(:[0-5]\d){0,2}(\x20[AP]M))|([01]\d|2[0-3])(:[0-5]\d){1,2})?$/;
    result = dateRegex.test(d + "-" + m + "-" + y);
  } catch (err) {
    result = false;
  }
  return result;
};

ComUtils.checkTwoDateStr = (startDateStr, endDateStr) => {
  if (startDateStr > endDateStr) {
    return false;
  }
  return true;
}

//Session TimeOut 시 예외 처리
ComUtils.checkSession = () => {

  if (!sessionStorage.getItem("userId")) { sessionStorage.setItem("userId", ""); }
  if (!sessionStorage.getItem("authorityId")) { sessionStorage.setItem("authorityId", ""); }
  if (!sessionStorage.getItem("empNo")) { sessionStorage.setItem("empNo", ""); }
  if (!sessionStorage.getItem("language")) { sessionStorage.setItem("language", ""); }
  if (!sessionStorage.getItem("userName")) { sessionStorage.setItem("userName", ""); }
  if (!sessionStorage.getItem("billTo")) { sessionStorage.setItem("billTo", ""); }
  if (!sessionStorage.getItem("billTo2")) { sessionStorage.setItem("billTo2", ""); }
  if (!sessionStorage.getItem("billTo3")) { sessionStorage.setItem("billTo3", ""); }
  if (!sessionStorage.getItem("bizUnit")) { sessionStorage.setItem("bizUnit", ""); }
  if (!sessionStorage.getItem("citiFlag")) { sessionStorage.setItem("citiFlag", ""); }
  if (!sessionStorage.getItem("comCode")) { sessionStorage.setItem("comCode", ""); }
  if (!sessionStorage.getItem("emailId")) { sessionStorage.setItem("emailId", ""); }
  if (!sessionStorage.getItem("storeName")) { sessionStorage.setItem("storeName", ""); }
  if (!sessionStorage.getItem("targetGroup")) { sessionStorage.setItem("targetGroup", ""); }
  if (!sessionStorage.getItem("today")) { sessionStorage.setItem("today", ""); }
  if (!sessionStorage.getItem("userGroup")) { sessionStorage.setItem("userGroup", ""); }
  if (!sessionStorage.getItem("userType")) { sessionStorage.setItem("userType", ""); }

}

ComUtils.setEpLoginSessionItem = (data) => {
  sessionStorage.setItem("epLogin", data?.epLogin ?? '');
  sessionStorage.setItem("type", data?.type ?? '');
  sessionStorage.setItem("viewType", data?.viewType ?? '');
  sessionStorage.setItem("reqId", data?.reqId ?? '');

  if (data?.type === "spl") {
    sessionStorage.setItem("actionType", data?.actionType ?? '');
    sessionStorage.setItem("dealType", data?.dealType ?? '');
    sessionStorage.setItem("readType", data?.readType ?? '');
    sessionStorage.setItem("targetEmpNo", data?.targetEmpNo ?? '');
  } else if (data?.type === "cac") {
    sessionStorage.setItem("entryNo", data?.entryNo ?? '');
    sessionStorage.setItem("ssosalesid", data?.ssosalesid ?? '');
  } else if (data?.type === "ir") {
    sessionStorage.setItem("actionTarget", data?.actionTarget ?? '');
  }
}

ComUtils.removeEpLoginSessionItem = () => {
  ApiManager.post("/init/deleteEPLoginAttributes", null, { loadbarOpen: false });
  cookie.remove("EP_LOGIN", { path: "/" });

  if (sessionStorage.getItem("type") === "spl") {
    sessionStorage.removeItem("actionType");
    sessionStorage.removeItem("dealType");
    sessionStorage.removeItem("readType");
    sessionStorage.removeItem("targetEmpNo");
  } else if (sessionStorage.getItem("type") === "cac") {
    sessionStorage.removeItem("entryNo");
    sessionStorage.removeItem("ssosalesid");
  } else if (sessionStorage.getItem("type") === "ir") {
    sessionStorage.removeItem("actionTarget");
  }

  sessionStorage.removeItem("epLogin");
  sessionStorage.removeItem("type");
  sessionStorage.removeItem("reqId");
  sessionStorage.removeItem("viewType");
}

/*
ComUtils.OpenContents = React.memo(function OpenContents({menu, linkInfo}){
    const LoadComponent = lazy(() => new Promise(async resolve => {
                                                    const module = await import(`../../pages/${menu.menuUrl}`);
                                                    setTimeout(() => resolve(module), 30);
                                                  }
                                        )
                            );
  return (

      <Suspense fallback={<div></div>}>
          <LoadComponent menuNtx={menu.ntxFlag||"N"}  menuTx={menu.txFlag||"N"} openTabMenuName={menu.parentNamePath} menuId={menu.menuCode} menuItems={menu.tabItems} currentMenu={menu} linkInfo={linkInfo||null}/>
      </Suspense>
  )

}, (prevProps, nextProps) => prevProps.menu.uniqueKey=== nextProps.menu.uniqueKey);

ComUtils.OpenContentKpiSummary = React.memo(function OpenContents({menu, linkInfo, callRelationFuntion}){
    const LoadComponent = lazy(() => new Promise(async resolve => {
                                                    const module = await import(`../../pages/${menu.menuUrl}`);
                                                    setTimeout(() => resolve(module), 30);
                                                  }
                                        )
                            );
  return (

      <Suspense fallback={<div></div>}>
          <LoadComponent menuNtx={menu.ntxFlag||"N"}  menuTx={menu.txFlag||"N"} openTabMenuName={menu.parentNamePath} menuId={menu.menuCode} menuItems={menu.tabItems} currentMenu={menu} linkInfo={linkInfo||null } callRelationFuntion={callRelationFuntion}/>
      </Suspense>
  )

}, (prevProps, nextProps) => prevProps.menu.uniqueKey=== nextProps.menu.uniqueKey);

ComUtils.OpenContentKpiDetail = React.memo(function OpenContents({menu, linkInfo, searchInfo}){
    const LoadComponent = lazy(() => new Promise(async resolve => {
                                                    const module = await import(`../../pages/${menu.menuUrl}`);
                                                    setTimeout(() => resolve(module), 30);
                                                  }
                                        )
                            );
  return (

      <Suspense fallback={<div></div>}>
          <LoadComponent menuNtx={menu.ntxFlag||"N"}  menuTx={menu.txFlag||"N"} openTabMenuName={menu.parentNamePath} menuId={menu.menuCode} menuItems={menu.tabItems} currentMenu={menu} linkInfo={linkInfo||null } searchInfo={searchInfo||null}/>
      </Suspense>
  )

}, (prevProps, nextProps) => prevProps.menu.uniqueKey=== nextProps.menu.uniqueKey && prevProps.searchInfo === nextProps.searchInfo);
*/

ComUtils.actorSubsidiaryCode = () => {
  return sessionStorage?.getItem("actorSubsdrCode")?.toString() ?? "";
};

ComUtils.actorDateFormat = () => {
  return sessionStorage?.getItem("actorDateFrmt")?.toString() ?? "";
}; // actorDateFrmt				MM/dd/yyyy

ComUtils.actorDateValid = () => {
  return sessionStorage?.getItem("actorDateValid")?.toString() ?? "";
}; // actorDateValid				__/__/____

ComUtils.actorDateMask = () => {
  return sessionStorage?.getItem("actorDateMask")?.toString() ?? "";
}; // actorDateMask 				##/##/####

ComUtils.actorDateMask = () => {
  return sessionStorage?.getItem("actorDateMask")?.toString() ?? "";
}; // actorDateMask 				##/##/####

// 파일 다운로드/업로드 할때 파일명에 /, \\, .. 가 있는 경우 제거하는 메서드
ComUtils.removeSpecialCharacters = (fileName) => {
  return fileName.replace(/\/|\\|\.\./g, '');
}

// sessionStorage에 gridFormat이 저장되어있는지 확인 후 해당 format 반환
ComUtils.applySessionStorageGridFormat = (gridFormat, menuId) => {
  const gridState = JSON.parse(sessionStorage.getItem("gridState"));
  if (gridState && gridState[menuId]) {
    const bindingOrder = _.cloneDeep(gridState[menuId]).map(
      (item) => item.binding
    );

    gridFormat.forEach((item) => {
      const targetColumn = gridState[menuId].find(
        (savedItem) => savedItem.binding === item.binding
      );

      if (targetColumn) {
        item.width = targetColumn.width;
      }

      if (item?.columns) {
        item.columns.forEach((subItem) => {
          const targetSubColumn = gridState[menuId].find(
            (savedItem) => savedItem.binding === subItem.binding
          );

          if (targetSubColumn) {
            subItem.width = targetSubColumn.width;
          }
        })
      }
    });

    gridFormat.sort(
      (a, b) =>
        bindingOrder.indexOf(a.columns ? a.columns[0].binding : a.binding) - bindingOrder.indexOf(b.columns ? b.columns[0].binding : b.binding)
    );
  }
}

ComUtils.isMobileDevice = () => {
  let sMobileDevice = /Mobi|Android|iPad|iPhone/i.test(window.navigator.userAgent);
  return sMobileDevice;
};

ComUtils.isWithinMonths = (d1, d2, months) => {
  const yearDiff = d2.getFullYear() - d1.getFullYear();
  const monthDiff = d2.getMonth() - d1.getMonth() + yearDiff * 12;

  if (monthDiff > months || (monthDiff === months && d2.getDate() > d1.getDate())) {
    return false; // 지정된 개월 수 이상
  }
  return true; // 지정된 개월 수 미만
};
