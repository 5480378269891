import React from "react";
import { Navigate } from "react-router-dom";

// Authentication related pages
// import LoginPage from "../Pages/Login/LoginPage";
// import EpLoginPage from "Pages/Login/EpLoginPage";
// import SignUpPage from "../Pages/Login/SignUpPage";

// import SignUpSpiff from "../Pages/Login/SignUpSpiff";
// import SignUpExternal from "../Pages/Login/SignUpExternal";

// Main Layout display
// import LayoutContainer from "../Layout/LayoutContainer";

// Authentication related pages
const LoginPage = React.lazy(() => import("../Pages/Login/LoginPage"));
const EpLoginPage = React.lazy(() => import("Pages/Login/EpLoginPage"));
const SignUpPage = React.lazy(() => import("../Pages/Login/SignUpPage"));
const SignUpSpiff = React.lazy(() => import("../Pages/Login/SignUpSpiff"));
const SignUpExternal = React.lazy(() =>
  import("../Pages/Login/SignUpExternal")
);

// Main Layout display
const LayoutContainer = React.lazy(() => import("../Layout/LayoutContainer"));

const protectedRoutes = [
  { path: "/", component: <LayoutContainer /> },
  // , { path: "/", exact: true, component: <Navigate to="/" />}
];

const publicRoutes = [
  { path: "/login", component: <LoginPage /> },
  { path: "/ep_login", component: <EpLoginPage /> },
  { path: "/sign-up", component: <SignUpPage /> },
  { path: "/sign-up-spiff", component: <SignUpSpiff /> },
  { path: "/sign-up-external", component: <SignUpExternal /> },
];

export { protectedRoutes, publicRoutes };
